import React, { useState } from 'react'
import API, { useAPIEventListener, useAPIConnectionListener } from '../API'
import TaskList from './TaskList'

export default function App(props: any) {
	const [connected, setConnected] = useState(API.shared.connected)
	useAPIConnectionListener((connected: any) => {
		setConnected(connected)
	})

	const [collaboratorsCount, setCollaboratorsCount] = useState(API.shared.collaboratorsCount)
	useAPIEventListener('collaborators', (collaborators) => {
		setCollaboratorsCount(collaborators.count)
	})

	return (
		<div className="Box">
			<div className="header">
				<div className="title">Tasks</div>
				{connected && (
					<div className="status">
						Connected!
						<br />
						<strong>{collaboratorsCount}</strong> collaborators online
					</div>
				)}
				{!connected && <div className="status">Connecting...</div>}
			</div>
			<TaskList />
		</div>
	)
}
