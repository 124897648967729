import React, { useState, useCallback } from 'react'
import API, { useAPIEventListener } from '../API'

export default function Task(props: any) {
	const [task, setTask] = useState(props.task)

	useAPIEventListener('task.updated', (updatedTask) => {
		if (updatedTask.id === task.id) {
			setTask(updatedTask)
		}
	})

	const updateTask = useCallback((data: any) => {
		setTask({ ...task, ...data })
		API.shared.call('task.update', { id: props.task.id, ...data })
	})

	const deleteTask = useCallback(() => {
		API.shared.call('task.delete', { id: props.task.id })
	})

	return (
		<div className="Task">
			<input className="checkbox" type="checkbox" checked={task.done} onChange={(e) => updateTask({ done: e.target.checked })} />
			<input className="body" type="text" value={task.body} onChange={(e) => updateTask({ body: e.target.value })} />
			<button className="deleteButton" onClick={deleteTask}>
				&#x2716;
			</button>
		</div>
	)
}
