import { useEffect } from 'react'

interface APIListener {
	type: string
	event?: string
	callback: (data: any) => void
	owner: any
}

export default class API {
	private socket: WebSocket
	private methodCallbacks: { [key: string]: (data: any) => void } = {}
	private listeners: APIListener[] = []
	private nextMessageId = 1

	public static shared = new API()

	public connected = false
	public collaboratorsCount = 0
	public tasks: any[] = []

	constructor() {
		this.connect()
		this.addEventListener('collaborators', this, (data) => {
			this.collaboratorsCount = data.count
		})
		this.addEventListener('task.list', this, (data) => {
			this.tasks = data
		})
	}

	private connect(): void {
		if (this.connected) return

		this.connected = false
		this.collaboratorsCount = 0

		let targetHost = window.location.hostname
		if (targetHost == 'localhost') {
			targetHost = 'localhost:6003'
		} else {
			targetHost = 'api-' + targetHost
		}
		if (window.location.protocol === 'https:') {
			targetHost = 'wss://' + targetHost
		} else {
			targetHost = 'ws://' + targetHost
		}
		this.socket = new WebSocket(targetHost)
		this.socket.onopen = () => {
			this.connected = true
			for (const listener of this.listeners) {
				if (listener.type == 'connection') {
					listener.callback(true)
				}
			}
		}
		this.socket.onmessage = (event) => {
			const message = JSON.parse(event.data)
			if (message.response) {
				this.methodCallbacks[message.id](message.response)
			} else if (message.event) {
				for (const listener of this.listeners) {
					if (listener.type == 'event' && listener.event === message.event) {
						listener.callback(message.data)
					}
				}
			}
		}
		this.socket.onerror = (error) => {
			console.error('Connection error:', error)
		}
		this.socket.onclose = () => {
			this.connected = false
			for (const listener of this.listeners) {
				if (listener.type == 'connection') {
					listener.callback(false)
				}
			}
			setTimeout(() => this.connect(), 1000)
		}
	}

	public async call(method: string, data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			const id = this.nextMessageId++
			this.methodCallbacks[id] = resolve
			this.socket.send(JSON.stringify({ id, method, data }))
		})
	}

	public addEventListener(event: string, owner: any, callback: (data: any) => void): void {
		this.listeners.push({ type: 'event', event, callback, owner })
	}

	public addConnectionListener(owner: any, callback: (connected: boolean) => void): void {
		this.listeners.push({ type: 'connection', callback, owner })
	}

	public removeListener(owner: any): void {
		this.listeners = this.listeners.filter((listener) => listener.owner !== owner)
	}
}

export function useAPIEventListener(event: string, callback: (data: any) => void): void {
	useEffect(() => {
		const owner = {}
		API.shared.addEventListener(event, owner, callback)
		return () => {
			API.shared.removeListener(owner)
		}
	}, [event, callback])
}

export function useAPIConnectionListener(callback: (connected: boolean) => void): void {
	useEffect(() => {
		const owner = {}
		API.shared.addConnectionListener(owner, callback)
		return () => {
			API.shared.removeListener(owner)
		}
	}, [callback])
}
