import React, { useState, useCallback } from 'react'
import API, { useAPIEventListener } from '../API'
import Task from './Task'

export default function TaskList(props: any) {
	const [tasks, setTasks] = useState(API.shared.tasks)

	useAPIEventListener('task.list', (tasks) => {
		setTasks(tasks)
	})

	useAPIEventListener('task.created', (task) => {
		setTasks([...tasks, task])
	})

	useAPIEventListener('task.deleted', (deletedTask) => {
		setTasks(tasks.filter((task) => task.id !== deletedTask.id))
	})

	const createTask = useCallback(() => {
		API.shared.call('task.create', { body: 'New Task' })
	})

	return (
		<div className="TaskList">
			<div className="tasks">
				{tasks.map((task) => (
					<Task key={task.id} task={task} />
				))}
			</div>
			<button className="addButton" onClick={createTask}>
				&#x271A; Add new task
			</button>
		</div>
	)
}
